@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

section.notFound {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5%;
  height: 80vh;
  font-family: "Press Start 2P";
  color: var(--title-1);
  text-align: center;
}

section.notFound h1 {
  color: red;
  font-size: 100px;
}

section.notFound h2 {
  font-size: 50px;
}

section.notFound h1,
section.notFound h2,
section.notFound h3 {
  margin-bottom: 40px;
}

section.notFound div.text {
  height: 50vh;
}

section.notFound div.text a,
section.notFound div.text a:visited {
  text-decoration: none;
  margin-right: 20px;
  color: inherit;
}

section.notFound div.text a:hover {
  color: red;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  section.notFound {
    flex-direction: column;
    justify-content: space-around;
  }
  section.notFound div.img img {
    width: 70vw;
    height: auto;
  }
  section.notFound h1 {
    font-size: 50px;
  }
  section.notFound h2 {
    font-size: 25px;
  }
  section.notFound div.text a:active {
    color: red;
    text-decoration: underline;
  }
}
