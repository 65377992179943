.connectors-form a {
    color: var(--web-link);
    text-decoration: none;
    font-weight: 700;
}

.connectors-form a:hover {
    text-decoration: underline 1px dashed;
}

.connectors-form span {
    font-size: 0.8rem;
}

.connectors-form span button {
    margin: 0;
}

.connectors-form span#sso > div {
    margin: 0;
}