.login-page {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  min-height: 600px;
  height: 100vh;
  min-width: 100vw;
  color: var(--title-4);
  position: absolute;
}
.bg-fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.2s ease;
}
.login-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-footer {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
.login-footer a {
  text-decoration: none;
  color: var(--header-button-title);
  margin: 10px;
  font-size: 12px;
}
.login-footer a:link,
.login-footer a:visited,
.login-footer a:hover,
.login-footer a:active {
  text-decoration: none;
}
.login-page h1 {
  color: var(--title-4);
  font-size: 10vw;
  text-align: center;
}

.login-page__action-container {
  text-align: center;
  width: 100%;
}

.login-banner {
  width: 100vw;
  display: flex;
  flex-direction: row;
  position: relative;
}

.login-banner span {
  display: none;
  text-decoration: none;
  color: var(--header-button-title);
  font-size: 3em;
  font-weight: 700;
  filter: drop-shadow(-3px -3px 8px rgb(0, 0, 0, 0.9));
  position: absolute;
  top: 38%;
}

.login-logo {
  height: auto;
  width: 100vw;
  padding: 45px 5px;
  filter: drop-shadow(-3px -1px 12px rgb(0, 0, 0));
}
.login-logo .st0:hover {
  filter: drop-shadow(-3px -3px 10px rgb(0, 0, 0, 0.4));
}
.login-logo .st1:hover {
  filter: drop-shadow(-3px -3px 12px rgba(0, 0, 0, 0.2));
}

.login-select-language {
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.login-form {
  background-color: var(--login-background);
  background-color: #1b4151;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 100vw;
}

.login-form-titles {
  color: var(--nhub-color-text-3);
  font-size: 20px;
  font-weight: 500;
}
.login-form-titles1 {
  font-weight: 700;
  margin-bottom: 10px;
}
.login-form-titles2 {
  /* color: #1b4151; */
  filter: drop-shadow(0 1px 8px rgb(0, 0, 0));
  font-weight: 500;
  text-align: right;
}
.login-form-subtitles {
  font-size: 14px;
}

.login-form-subtitles-2 {
  font-size: 12px;
}

.login-form-subContainer {
  display: flex;
  /* padding: 15px 0; */
  width: 100vw;
}
.login-form-subContainer1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
gap: 30px;
  background-color: #1b4151;
  padding: 20px 25px;
}
.login-form-subContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.login-form-toggleButton-container {
  position: relative;
  overflow: hidden;
  display: flex;
  margin-top: 20px;
  width: 350px;
  padding: 0px;
  border: 1px solid var(--nhub-color-background-6);
  border-radius: 10px;
  /* background-color: color-mix(in srgb, var(--button-background-hover-9) 80%, black) */
  background-color: var(--button-background-hover-9);
}
.login-form-toggleButton-container:hover {
  /* background-color: color-mix(in srgb, var(--button-background-9) 80%, black) */
  background-color: var(--button-background-9);
}

.login-form-toggleButton-container button {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.login-form-toggleButton-container button::after {
  content: "";
  background: var(--button-background-8);
  position: absolute;
  z-index: -1;
  display: block;
  border-radius: 10px;
  transition: all 0.35s;
}

.slide_from_left_active::after,
.slide_from_right_active::after {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.35s;
}
.slide_from_left::after {
  top: 0;
  bottom: 0;
  left: -100%;
  right: 100%;
}
.slide_from_right::after {
  top: 0;
  bottom: 0;
  left: 100%;
  right: -100%;
}
.login-form-text-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 210px;
}
#login-form-text-slide1 {
  position: absolute;
  left: -100%;
  width: 100%;
  transition: 0.35s;
}
.login-form-text-container_active #login-form-text-slide1 {
  transition: 0.35s;
  left: 0;
}
#login-form-text-slide2 {
  position: absolute;
  right: 0;
  width: 100%;
  transition: 0.35s;
}
.login-form-text-container_active #login-form-text-slide2 {
  transition: 0.35s;
  right: -100%;
}
.login-form-subtitles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: inherit;
  gap: 20px;
}
.login-form-subtitles-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.login-form-subtitles-buttons span {
  font-size: 13px;
}

@media screen and (min-width: 350px) {
  .login-logo {
    padding: 45px;
  }
  .login-form-titles2 {
    margin-right: 10px;
  }
}
@media screen and (min-width: 400px) {
  .login-form-titles {
    font-size: 20px;
  }
  .login-form-titles1 {
    font-size: 30px;
  }
  .login-form-subtitles {
    font-size: 16px;
    /* justify-content: space-around; */
  }
  .login-form-subtitles-2 {
    font-size: 14px;
  }
}
@media screen and (min-width: 600px) {
  .login-form-subContainer {
    width: auto;
    border-radius: 15px;
    max-width: 550px;
  }
  .login-form__login-button {
    max-width: 70%;
  }
}

@media screen and (min-width: 700px) {
  .login-form {
    min-height: 45vh;
    border-radius: 50px;
    width: 100%;
    height: 100%;
    padding: 40px;
    gap: 30px;
    min-width: 560px;
  }
  .login-logo {
    height: 12.5em;
    width: auto;
    padding: 45px 25px 45px 45px;
  }
  .login-page h1 {
    font-size: 60px;
  }
  .login-banner span {
    display: inline-block;
  }

}

@media screen and (min-width: 1100px) {
  .login-select-language {
    right: 10px;
  }
  .login-page__action-container {
    max-width: 70%;
  }
}
