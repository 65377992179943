.campaignItem-container {
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.campaignItem-identity
{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.campaignItem-logo {
    width: 130px;
    height: 100px;
    overflow: hidden;
    background-color: var(--nhub-color-background-5);
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.campaignItem-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.campaignItem-contentCenter {
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.campaignItem-contentCenter h1 {
    font-size: 18px;
    font-weight: 400;
    color: var(--title-1);
}

.campaignItem-status {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid var(--nhub-color-secondary-5);
    margin-right: 5px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--nhub-color-secondary-5);
}

.campaignItem-status.on-hold {
    border: 2px solid var(--nhub-color-secondary-6);
    color: var(--nhub-color-secondary-6);
    font-style: italic;

}
.campaignItem-status.not-started {
    border: 2px solid var(--nhub-color-secondary-4);
    color: var(--nhub-color-secondary-4);

}

.campaignItem-status.finished {
    border: 2px solid var(--nhub-color-secondary-6);
    color: var(--nhub-color-secondary-6);

}

.campaignItem-dates {
    white-space: nowrap;
}

.campaignItem-contentRight {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.campaignItem-bounties {
    padding: 10px;
    border: 2px solid var(--nhub-color-secondary-2);
    border-radius: 10px;
    min-width: 200px;
}
.campaignItem-bounties h3 {
    text-align: center;
    margin: 0px;
    font-size: 14px;
    color: var(--nhub-color-secondary-2);
}

.campaignItem-bounties span {
    font-size: 26px;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    display: block;
}

.campaignItem-title {
    text-decoration: none;
}
.campaignItem-disabled {
    pointer-events: none;
    opacity: 0.5;
    background: #c2c2c2;
}

@media screen and (max-width: 830px){
    .campaignItem-container {
        flex-direction: column;
    }
    .campaignItem-bounties span {
        white-space: wrap;
        font-size: 15px;
    }

    .campaignItem-bounties {
        min-width: 0;
    }


.campaignItem-statusDates {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
}
}

@media screen and (max-width: 500px){
    .campaignItem-identity {
        flex-direction: column;
        align-items: center;
    }
}