.information-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 0px;
  padding-bottom: 20px;
  margin-bottom: 20px;

  max-width: 700px;
  margin: auto;

  border: 1px solid var( --box-border);
  border-radius: 10px;
  padding: 20px;
}

.information-form h3 {
  margin: 0px;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--title-1);
}

.information-form-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  height: 100%;
}
.information-form-title span {
  color: var(--title-1);
  text-align: right;
}
.information-form-title sup {
  color: var(--title-3);
  padding-left: 3px;
}
span.information-form-title-optional {
  color: var(--title-2);
  font-size: 12px;
  margin-left: 3px;
}

.information-form-intro {
  text-align: justify;
  margin: 20px 10px;
}

/* --- USER SINGLE --- */

.single-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin-bottom: 20px; */

  /* max-width: 1400px; */
}

.button-middle {
  padding: 16px 0px 16px 0px;
  /* margin: 0 26px 0 26px; */
  margin: 0;
}

.widgetFormValuesInput input,
.widgetFormValuesInput select {
  width: 100px;
  background: none;
  border: none;
  color: var(--title-2);
  font-size: 12px;
  font-weight: 400;
  padding: 0;
}
.widgetProfileFormValuesInput input,
.widgetProfileFormValuesInput select,
div.widgetProfileFormValuesInput {
  width: 200px;
  /* width: 100%; */
}
.widgetFormValuesInput input:hover,
.widgetFormValuesInput select:hover {
  background: var(--table-tr-hover-background);
}
.widgetFormValuesTextArea textarea,
.widgetFormValuesTextArea input,
.widgetFormValuesTextArea label {
  width: 100%;
  background: none;
  border: none;
  color: var(--title-2);
  font-size: 12px;
  font-weight: 400;
}

@media screen and (min-width: 600px) {
  /* .information-form {
    padding-top: 45px;
  } */

  .button-middle {
    padding: 25px 0px 25px 0px;
  }
}


.separator {
  min-width: 50%;
  border-bottom: 1px solid var(--table-border);
  margin: 20px auto;
}

.optionalValue {
  color: var(--table-tr-locked-text);
}

@media screen and (max-width: 900px) {
  .information-form {
    max-width: none;
    margin: 0;
  }
}
