#campaign-container h1 {
    text-align: center;
    color: var(--title-1);
    font-size: 24px;
}

.campaign-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 750px);
    grid-gap: 20px;
    justify-content: center;
}

@media screen and (max-width: 830px){
    .campaign-grid {
        grid-template-columns: repeat(auto-fill, 450px);

    }
}

@media screen and (max-width: 500px){
    .campaign-grid {
        grid-template-columns: repeat(auto-fill, 300px);

    }
}