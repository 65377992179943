.nhub-investigations-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.invest-flexbox {
  color: var(--title-4);
  padding: 7px;
  min-width: 10em;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;

  border: 1px solid var(--box-border);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.invest-flexbox span {
  padding: 0 5px;
}
.box-invest-total {
  background-color: var(--box-background-2);
}
.box-invest-open {
  background-color: var(--box-background-5);
}
.box-invest-resolved {
  background-color: var(--box-background-4);
}
.box-invest-pending {
  background-color: var(--box-background-3);
}

.titleBox {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.valueBox {
  font-weight: 275;
  font-size: 36px;
  line-height: 40px;
}

.invest-table-container {
  width: 100%;
}

/* INVESTIGATIONS - Single */

.nhub-invest-body-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.invest-menu-tab-bar {
  background-color: var(--main-background);
  color: var(--content-text);
  position: fixed;
  top: calc(var(--header-height) + 45px);
  z-index: 1;
  right: 0;
  left: var(--MainNav-width);
}
.investBox-content {
  width: 100%;
  padding-top: calc(var(--header-height) + 2em);
}

/* ---- INVESTIGATION > SUMMARY----- */

.nhub-invest-content-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.widget-invest-resolved {
  background-color: var(--tag-background);
  position: relative;
}

.widget-invest-resolved h5 {
  margin-right: 50px;
}

.icoBlueShield {
  position: absolute;
  top: 7px;
  right: 7px;
  height: 50px;
  width: 50px;
}

/* ---- INVESTIGATION > ALERTS ----- */
.investBox-alert-table {
  width: 100%;
}
/* ---- INVESTIGATION > MITRE ATT&CK ----- */
.nhub-mitre-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
}
.mitreBox-header {
  color: var(--title-5);
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
.mitreBox-header img {
  width: 100%;
  height: 5vh;
  align-items: center;
  position: relative;
}
.mitreBox-header-col {
  width: 100%;
  height: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.criticality-span {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: var(--title-4);
}
.mitre-number {
  box-sizing: border-box;
  border-radius: 50px 50px;
  border-style: solid;
  border-width: 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding: 2px 0px 2px 0px;
  width: 30px;
  margin: auto;
  margin-bottom: 2px;
}
.mitre-number-0 {
  background-color: var(--mitre-background-1);
  color: var(--title-5);
}
.mitre-number-1 {
  background-color: var(--mitre-background-2);
  color: var(--title-4);
}
.mitreBox-body {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 97%;
  height: 100%;
  margin-bottom: auto;
}
.mitreBox-col1 {
  color: var(--title-5);
  width: 20%;
  display: flex;
  flex-flow: column;
  align-items: center;
  align-self: center;
}
.mitreBox-col1 svg {
  height: 30px;
  width: auto;
}
.mitreBox-row1 {
  color: var(--mitre-row-1);
  margin: 8px;
  padding: 8px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-self: center;
  width: 124px;
}
.mitreBox-row1 span {
  font-size: 14px;
  font-weight: 400;
}
.mitreBox-row1 a {
  color: var(--title-2);
}
.mitreBox-row1 a :hover:not(.active) {
  color: var(--web-link);
}

/* ---- INVESTIGATION > HISTORY ----- */

.history-container {
  max-height: calc(100vh - 175px);
  overflow-y: auto;
  width: 100%;
}
.history-box {
  margin: 10px 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1000px;
}
.avatar {
  height: 40px;
  width: 46px;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: var(--nhub-color1);
  color: var(--title-4);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  font-weight: 700;
  font-size: 18px;
}
.avatarSkeleton {background-color: transparent;}
.avatar img {
  height: 30px;
  width: 30px;
  text-align: center;
}

.history-user {
  position: absolute;
  padding-left: 60px;
  width: 15em;
  color: var(--title-1);
  font-size: 14px;
  font-weight: 400;
}
.history-description {
  position: absolute;
  left: 15em;
  right: 50px;
  padding: 0 60px 0 60px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}
.history-date {
  position: absolute;
  right: 10px;
  width: 100px;
  text-align: right;

  color: var(--title-1);
  font-size: 14px;
  font-weight: 400;
}

/* ---- INVESTIGATION > COMMENTS ----- */

.comments-container {
  max-height: calc(100vh - 275px);
  overflow-y: auto;
  width: 100%;
}
.comment-box {
  margin: 20px 10px;
  position: relative;
}
.comment-user {
  position: absolute;
  top: 10px;
  padding-left: 60px;
  min-width: 15em;
  color: var(--title-1);
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.comment-date {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--title-1);
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
}
.comment-value {
  padding: 0 60px 0 60px;
  margin: 0;
  margin-top: 10px;
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
}
.comment-input {
  position: sticky;
  bottom: 0;
  padding: 0em 50px 1em 0;
  margin-top: 30px;
  z-index: 0;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* MOBILE */
@media screen and (max-width: 768px) and (orientation: portrait) {
  .nhub-investigations-container {
    justify-content: center;
  }
  .invest-flexbox {
    flex-direction: row;
    justify-content: space-between;
    min-width: 0;
  }
  .titleBox {
    font-size: 12px;
    line-height: 18px;
  }
  .valueBox {
    font-size: 18px;
    line-height: 18px;
  }

  .invest-table-container {
    overflow: scroll;
  }

  .invest-menu-tab-bar {
    position: inherit;
    background-color: inherit;
  }

  .invest-menu-tab-bar ul {
    margin: 0;
  }
  .investBox-content {
    padding-top: 0;
    padding-right: 0;
  }
  .nhub-invest-content-container {
    flex-direction: column;
  }

  /* MITRE */

  .mitreBox-header img {
    height: 30px;
  }

  .mitre-number {
    font-size: 12px;
    padding: 0px;
    width: 18px;
    margin-bottom: 0px;
  }

  .mitreBox-col1 svg {
    height: 20px;
    width: auto;
  }

  .mitreBox-row1 {
    margin: 2px;
    padding: 2px;
    font-size: 12px;
    width: 100%;
  }

  .mitreBox-row1 span {
    font-size: 9px;
  }

  /* HISTORY */
  .history-box {
    margin: 10px 0px;
    max-width: 100%;
  }
  .avatar {
    height: 20px;
    width: 23px;
    font-size: 9px;
  }
  .avatar img {
    height: 15px;
    width: 15px;
  }

  .history-user {
    padding-left: 28px;
    width: 10em;
    font-size: 10px;
  }
  .history-description {
    left: 6em;
    right: 10px;
    padding: 0 60px 0 60px;
    margin: 0;
    font-size: 10px;
  }
  .history-date {
    right: 10px;
    width: 60px;
    font-size: 8px;
  }

  /* COMMENTS */
  .comments-container {
    max-height: calc(100vh - 290px);
  }
  .comment-box {
    margin: 10px 0px;
    position: relative;
  }
  .comment-user {
    top: 0px;
    padding-left: 30px;
    width: 15em;
    font-size: 12px;
    line-height: 40px;
  }
  .comment-date {
    top: 0px;
    font-size: 10px;
  }
  .comment-value {
    padding: 0 5px 0 5px;
    margin: 0;
    margin-top: 10px;
    font-size: 12px;
  }
  .comment-input {
    bottom: 5px;
    padding: 0em 0px 0em 0;
    margin-top: 10px;
    width: 100%;
    height: 10px;
  }
  .comment-input label,
  .comment-input textarea {
    font-size: 12px;
    line-height: 9px;
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .invest-flexbox {
    flex-direction: row;
    justify-content: space-between;
    min-width: 0;
  }
  .titleBox {
    font-size: 12px;
    line-height: 18px;
  }
  .valueBox {
    font-size: 18px;
    line-height: 18px;
  }

  .invest-menu-tab-bar {
    position: inherit;
    background-color: inherit;
  }

  .investBox-content {
    padding-top: 0;
    padding-right: 0;
  }

  /* .invest-table-container {
    overflow: scroll;
  } */

  /* .invest-menu-tab-bar {
    top: calc(var(--header-height) + 90px);
  } */
  /* .invest-menu-tab-bar ul {
    margin: 0;
  } */
  /* .investBox-content {
    padding-top: calc(var(--header-height) + 2em + 25px);
    padding-right: 0;
  } */
  /* .nhub-invest-content-container {
    flex-direction: column;
  } */

  /* MITRE */

  .mitreBox-header img {
    height: 35px;
  }

  .mitre-number {
    font-size: 12px;
    padding: 0px;
    width: 18px;
    margin-bottom: 0px;
  }

  .mitreBox-col1 svg {
    height: 20px;
    width: auto;
  }

  .mitreBox-row1 {
    margin: 2px;
    padding: 2px;
    font-size: 12px;
    width: 100%;
  }

  .mitreBox-row1 span {
    font-size: 10px;
  }

  /* HISTORY */
  .history-box {
    margin: 10px 0px;
    max-width: 100%;
  }
  .avatar {
    height: 20px;
    width: 23px;
    font-size: 9px;
  }
  .avatar img {
    height: 15px;
    width: 15px;
  }

  .history-user {
    padding-left: 28px;
    width: 150px;
    font-size: 10px;
  }
  .history-description {
    left: 178px;
    right: 100px;
    padding: 0;
    margin: 0;
    font-size: 10px;
  }
  .history-date {
    right: 10px;
    width: 100px;
    font-size: 10px;
  }

  /* COMMENTS */
  .comments-container {
    max-height: calc(100vh - 220px);
  }
  .comment-box {
    margin: 10px 0px;
    position: relative;
  }
  .comment-user {
    top: 0px;
    padding-left: 30px;
    width: 15em;
    font-size: 12px;
    line-height: 40px;
  }
  .comment-date {
    top: 0px;
    font-size: 10px;
  }
  .comment-value {
    padding: 0 5px 0 5px;
    margin: 0;
    margin-top: 10px;
    font-size: 12px;
  }
  .comment-input {
    bottom: 5px;
    padding: 0em 0px 0em 0;
    margin-top: 10px;
    width: 100%;
    height: 10px;
  }
  .comment-input label,
  .comment-input textarea {
    font-size: 12px;
    line-height: 9px;
  }
}
