@media screen {
  .pagedjs_pages {
    display: flex;
    width: calc(var(--pagedjs-width));
    flex: 0;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
  }
  .pagedjs_page {
    margin: 10mm;
    border: solid 1px gray;
    background: white;
  }
  #previewer {
    background-color: lightgrey;
    padding: 10px 0px;
  }
}

@media screen and (max-width: 768px) and (orientation: portrait){
  .pagedjs_pages {
    width: 100%;
  }
  .pagedjs_pages {
    transform: scaleX(0.4) scaleY(0.4);
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape){
  .pagedjs_pages {
    width: 100%;
  }
  .pagedjs_pages {
    transform: scaleX(0.6) scaleY(0.6);
  }
}
