.registration__container {
  max-width: 1000px;
  margin: auto;
}

.candidate-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 17px;
  margin-top: 30px;
}

h2.registration__header {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.registration__menu-tab-bar {
  border: 1px solid var( --box-border);
  border-radius: 10px;
  overflow: hidden;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
}

ul.registration-menu {
  list-style-type: none;
  padding: 0px;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  white-space: nowrap;
}
ul.registration-menu li {
  padding: 10px 20px;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
ul.registration-menu li a {
  color: var(--menu-tab-text);
  text-decoration: none;
  position: relative;
}
ul.registration-menu li a.active {
  color: var(--menu-tab-active-text);
  border-bottom: 1px solid var(--menu-tab-active-border);
  /*padding: 5px 0px;*/
  /*background-color: rgba(0, 95, 147,0.1)*/
}
ul.registration-menu li a:not(.active)::after {
  color: var(--menu-tab-hover-text);
  background: var(--nhub-color1);
  bottom: -6px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  -webkit-transition: width 0.2s linear, left 0.2s linear;
  transition: width 0.2s linear, left 0.2s linear;
  width: 0;
}
ul.registration-menu li a:hover:not(.active)::after {
  color: var(--menu-tab-hover-text);
  height: 1px;
  left: 0;
  width: 100%;
  padding: 0px;
}

@media screen and (max-width: 900px) {
  .candidate-container {
    flex-direction: column;
    align-items: stretch;
  }
}