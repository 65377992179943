.nhub-hunt-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
}
/* .nhub-hunt-container .widget{
  height: 100%;
  margin: 0;
} */

.campaign-line {
  width: 100%;
  color: var(--table-tr-text);
  min-width: 60%;
  max-width: 100%;
  border: none;
  margin-right: 30px;
  padding-bottom: 1em;
  display: flex;
}
.campaign-line label {
  font-size: 12px;
  font-weight: 400;
}
.campaign-line div {
  font-size: 12px;
  font-weight: 400;
}
.campaign-line h5 {
  padding-right: 20px;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 400;
}
.campaign-box {
  width: 40%;
}
.report-table-container {
  color: var(--table-tr-text);
  width: 70%;
  padding-bottom: 20px;
}
.hunt-button-container {
  padding-top: 20px;
}

/* --- REPORTS PAGE --- */

.report-title {
  color: var(--title-1);
  font-size: 14px !important;
  font-weight: 400 !important;
}
.nhub-button button {
  background-color: var(--button-background-1);
  color: var(--title-1);
}
.nhub-button button:hover {
  background-color: var(--button-background-hover-1);
  color: var(--title-1);
}
.AlertInfo {
  padding-right: 30px;
}

/* --- EDIT REPORT --- */

.report-container {
  padding-top: 30px;
}
.menu-title-campaignName {
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
}
.report-button-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
}
.report-form {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  gap: 20px;
}
.report-form-widget {
  margin: 0;
  height: 100%;
  position: relative;
}
.report-form-widget div,
.report-form-widget textarea {
  font-size: 12px;
  font-weight: 400;
}
.report-form-widget div::before {
  border: none;
}
.report-form-widget button {
  position: absolute;
  right: 0px;
  top: 0px;
}
.report-form-widget svg {
  font-size: 16px;
  color: var(--widget-button);
}

/* --- CAMPAIGN SINGLE --- */

.widgetFormValuesInput input,
.widgetFormValuesInput select {
  width: 100px;
  background: none;
  border: none;
  color: var(--title-2);
  font-size: 12px;
  font-weight: 400;
  padding: 0;
}
.widgetProfileFormValuesInput input,
.widgetProfileFormValuesInput select, div.widgetProfileFormValuesInput {
  /* width: 200px; */
  width: 100%;
}
.widgetFormValuesInput input:hover,
.widgetFormValuesInput select:hover {
  background: var(--table-tr-hover-background);
}
.widgetFormValuesTextArea textarea,
.widgetFormValuesTextArea input,
.widgetFormValuesTextArea label {
  width: 100%;
  background: none;
  border: none;
  color: var(--title-2);
  font-size: 12px;
  font-weight: 400;
}
.CampaignSingle-CreateReport {
  margin-top: -45px;
}
.date-picker-form {
  width: 200px;
}

/* --- ADMIN - HUNTING CAMPAIGNS PAGE --- */

.campaignBox-content {
  width: 100%;
  padding-top: 45px;
}
.nhub-campaignSingle-content .widget {
  height: 100%;
}
.nhub-campaignSingle-content {
  width: 100%;
  padding-bottom: 10px;
}

.nhub-campaign-content-container {
  max-height: calc(100vh - 130px);
}
.campaignBox-1 {
  color: var(--title-2);
  background-color: var(--main-background);
  width: 100%;
  height: 4em;
  z-index: 1;
}
.campaignBox-1 h4 {
  margin: 0;
}
.campaignBox-1 a {
  color: var(--title-1);
  text-decoration: none;
}
.admin-tab {
  top: var(--header-height);
}
ul.campaign-bar-items {
  list-style-type: none;
  margin: 10px;
  padding: 0;
  font-size: larger;
}
ul.campaign-bar-items li a {
  color: var(--title-2);
  padding: 8px 16px;
  text-decoration: none;
}
ul.campaign-bar-items li a.active {
  color: var(--title-1);
}
ul.campaign-bar-items li a:hover:not(.active) {
  color: var(--title-2);
}
.campaignBox-3 {
  margin: 1em 0;
}
.campaignBox-4 {
  padding-top: 1em;
}

/* --- ADMIN - SELECT HUNTER PAGE --- */

.dialog-subtitle {
  color: var(--nhub-color-secondary-2);
}
.hunterBox-container {
  --select-minHeight: 500px;
}
.hunterBox-container button {
  width: 50%;
}

/* ---  --- */

@media screen and (max-width: 768px) and (orientation: portrait) {
  .report-table-container {
    width: 100%;
  }
  .report-table {
    max-height: none;
  }
  .admin-tab {
    top: calc(var(--header-height) + 40px);
  }

  .campaignBox-content {
    padding-top: 25px;
  }
  /* --- EDIT REPORT --- */

  .report-container {
    padding-top: 0px;
  }
  .report-form {
    max-height: 100%;
  }

  /* --- ADMIN - HUNTING CAMPAIGNS PAGE --- */

.nhub-campaign-content-container {
  overflow-y:inherit;
}

  /* --- ADMIN - SELECT HUNTER PAGE --- */
  .hunterBox-container {
    --select-minHeight: 0px;
  }
  .hunterBox-container option{
    overflow-x: scroll;
  }
  .hunterBox-container button {
    margin: 5px;
    font-size: 10px;
    left: -20px;
  }
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
  .report-table-container {
    width: 100%;
  }
  .report-table {
    max-height: none;
  }
  .campaignBox-content {
    padding-top: 25px;
  }
  /* --- EDIT REPORT --- */

  .report-container {
    padding-top: 0px;
  }
  .report-form {
    max-height: 100%;
  }

  /* --- ADMIN - HUNTING CAMPAIGNS PAGE --- */

.nhub-campaign-content-container {
  overflow-y:inherit;
}

  /* --- ADMIN - SELECT HUNTER PAGE --- */
  .hunterBox-container {
    --select-minHeight: 0px;
  }
  .hunterBox-container option{
    overflow-x: scroll;
  }
  .hunterBox-container button {
    margin: 5px;
  }
}
