/* Register from */

.register-form {
    width: 100%;
  
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
  }
  
  .register-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .register-form-titles {
    margin-bottom: 10px;
  }
  .register-buttons-container {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  .register-buttons-container span {
    font-size: 12px;
  }
  
  .passwordTooltip-container {
    position: relative;
  }
  .passwordTooltip ul {
    list-style-type: none;
    margin-top: 0;
  }
  .passwordTooltip {
    display: flex;
    position: absolute;
    z-index: 10;
    right: 5%;
    left: 5%;
    border: 1px solid var(--box-border);
    border-top: none;
    border-radius: 0px 0px 50px 50px;
    background-color: var(--login-box);
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .passwordTooltip h5 {
    font-weight: 600;
    color: var(--title-4);
  }
  .passwordTooltip svg {
    vertical-align: middle;
    width: 21px;
    height: 21px;
  }