.nhub-overview-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  height: 100%;
}

.chart {
  /* calc viewHeight required - header height - marginTop */
  /*min-height: calc(47vh - (var(--header-height)) - 20px);*/
  height: calc(47vh - (var(--header-height)) - 20px);
  display: flex;
  flex-direction: column;
  margin: 0;
}
.barChart {
  /* calc viewHeight required - header height*/
  /*min-height: calc(53vh - (var(--header-height)));*/
  height: calc(53vh - (var(--header-height)));
}

.chart-item {
  flex: 1 1 auto;
  position: relative;
  height: auto;
  overflow: hidden;
}
.chart-label {
  padding-top: 5px;
  padding-bottom: -8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.chart-noData {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
}

/* MOBILE */
@media screen and (max-width: 768px) {
  .MuiToggleButtonGroup-root button {
    padding: 0;
  }
}
@media screen and (max-width: 1000px) {
  .MuiToggleButtonGroup-root button {
    padding: 0;
  }

  .chart {
    height: 65vh;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .barChart {
    height: 65vh;
  }
  
}
