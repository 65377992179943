.nhub-alert-container {
    display: flex;
    flex-flow: row wrap;    
    justify-content: space-evenly;
    height: 100%;
}

.alert-table-container{
    color: var(--menu-tab-text);
	width: 100%;
    box-sizing: border-box;
}


/* ---- ALERTS Single content ----- */

.alert-content {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start; */
    padding-top: 45px;
}

.button-middle{
    padding: 25px 0px 90px 00px;
    margin: 0 46px 0 26px ;
}

#alert-right-column {
    max-width: calc(100% - 350px);
}


/* ---- ALERTS > SIGMA DETAILS ----- */

.alerts-sigma-content{
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.alerts-sigma-content table{
    border-collapse: collapse;
}
.alerts-sigma-content td{
    padding-bottom: 3px;
}

.sigma-values{
    display:flex;
}

.sigma-values a{
    color: var(--web-link);
    text-decoration: none;
    font-weight: 700;
}
.sigma-values a:hover{text-decoration: underline 1px dashed;}


span.tags, a.tags{
    background-color: var(--tag-background);
    color: var(--tag-color);
    box-sizing: border-box;
    border-radius: 5px 5px;
    border: 1px dashed var(--tag-border-color);
    margin: 2px ;
    padding: 0 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    max-width: var(--sigmaWidth);
    overflow: hidden;
    text-overflow: ellipsis;
}
.tagsRow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.noFields{padding-left: 3px;}

.fieldsCell {
    display: flex;
    flex-direction: column;
}
.fieldsRow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.fieldTitle{
    font-size: 12px;
    font-weight: 500;
}

.fieldTitle a {
    color: var(--web-link);
    text-decoration: none;
    font-weight: 700;
}

.fieldTitle a:hover {
    text-decoration: underline 1px dashed;
  }

.refSigma{margin-right: 10px;}


/* ---- ALERTS > RAW EVENT ----- */
.json-view{
    padding-left: 1em;
    font-size: x-large;
    border-color: none;
}
.icon{font-size:larger;}

.dialog-link:link {
text-decoration: none;
color: var(--raw-dialog);
}

.dialog-link:visited {
text-decoration: none;
color: var(--raw-dialog);
}

.dialog-link:hover {
text-decoration: none;
color: var(--raw-dialog);
}

.dialog-link:active {
text-decoration: none;
color: var(--raw-dialog);
}



@media screen and (max-width: 768px) and (orientation: portrait)
{
    .alert-content{
        flex-direction: column;
        padding-top: 0;
    }
    .button-middle {
        padding: 16px 0px 0px 0px;
    }
    .alerts-sigma-content span{
        white-space: pre-line;
    }
    #alert-right-column {
        max-width: 100%;
        width: 100%;
    }
}

/* < 900px (< md) : on remet la colonne de droite en dessous */
@media screen and (max-width: 900px) {
    #alert-right-column {
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 900px) and (orientation: landscape)
{
    .alert-content{
        flex-direction: column;
        padding-top: 0;
    }
    .button-middle {
        padding: 16px 0px 0px 0px;
    }
    .alerts-sigma-content span{
        white-space: pre-line;
    }
    #alert-right-column {
        max-width: 100%;
        width: 100%;
    }
}