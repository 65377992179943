/* --- LOADING SPINNER --- */

.overlay {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--nhub-color-background-2);
  opacity: 0.6;
}

.loader-container {
  z-index: 10;
  width: 20vh;
  height: 20vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader-container svg {
  animation: scaling 0.4s alternate infinite ease-in-out;
}
@keyframes scaling {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.25);
  }
}
