.scoringContainer {
  position: relative;
}

.scoringContainer h4 {
  color: var(--title-1);
  font-size: 20px;
  line-height: 21px;
  margin: 0;
  padding: 10px 0;
}

.scoringHeader {
  display: flex;
  gap: 10px;

  position: absolute;
  top: 0px;
  right: 10px;
}

.scoringDatePickerContainer {
  display: flex;
  flex-direction: row;
}

.scoringDatePickerContainer input {
  width: 95px;
}

.scoringRank1DayContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: stretch;
  justify-content: space-around;
}

.scoringRank1DaySubContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.scoringRank1DayTextContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  width: 600px;
  justify-content: flex-start;
}

.scoringRank1DayChartContainer {
  margin: 40px;
  width: 500px;
  height: 500px;
}

.cartOpening {
  cursor: pointer;
}

.cartOpening svg {
  height: 25px;
  width: auto;
  margin: 5px 5px -5px 5px;
}

.scoringWidgetCart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.scoringWidget {
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;

  border: 11px solid var(--scoring-label-color1);
  border-radius: 50%;
  height: 180px;
  width: 180px;
  font-size: 24px;
}

.scoringWidget h5 {
  font-size: 24px;
  font-weight: 400;
}

.rankNote {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.2;
}

.rankNbTotal {
  font-size: 23px;
  font-weight: 400;
}

.helperTextTitle {
  color: var(--title-1);
  font-weight: 500;
}


.cell-align {
  text-align: center
}

.cell-top-rank {
  background-color: var(--scoring-top-rank);
}

.cell-middle-rank {
  background-color: var(--scoring-middle-rank);
}

.cell-bottom-rank {
  background-color: var(--scoring-bottom-rank);
}

.scoringRankRangeContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
}

.scoringRankRangeSubContainer {
  display: flex;
  flex-direction: row;
  gap: 7px;
  flex-wrap: wrap;
}

.scoringRankRangeChartContainer {
  margin: 20px 0;
  height: 400px;
}

.scoringNoData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;

  height: 200px;
  width: 100%;
  margin-top: 20px;
  color: var(--nhub-color1);
}

.scoringNoData svg {
  height: 70px;
  width: auto;
  padding: 8px;

  border-radius: 50%;
  background-color: var(--nhub-color-background-5);
}

@media screen and (max-width: 1300px) {
  .scoringRank1DayChartContainer {
    width: 35vw;
    height: 35vw;
  }  
  .scoringRank1DayTextContainer{
    width: 40vw;
  }
}

@media screen and (max-width: 900px) {
  .scoringHeader {
    flex-direction: column;
    position: relative;
    width: min-content;
    padding: 10px 0;
  }
  .scoringRank1DayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .scoringRank1DayChartContainer {
    margin: 0;
    width: 80vw;
    height: 80vw;
  }
  .scoringRank1DayTextContainer{
    width: 80vw;
  }
  
}

@media screen and (max-width: 400px) {
  .scoringHeader {
    width: 100%;
  }

  .scoringDatePickerContainer input {
    width: 100%;
  }
}