.notificationType-item {
  list-style: none;
  width: 100%;
}
.notificationType-item li {
  margin: 5px 0;
  list-style-type: none;
}
.notificationType-item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.notificationType-container {
  display: flex;
  flex-direction: column;
}
.notificationType-helper {
  margin: 5px 0;
}
.notificationType-container span {
  font-size: 12px;
  font-weight: 400;
}
.notificationPreferencesSelector {
  align-items: center;
  background-color: var(--wiki-background-1);
  border: 1px solid var(--wiki-border-1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  margin-top: 2px;
  padding: 10px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  z-index: 10;
}
.notificationPreferencesSelector h5 {
  font-size: 13px;
}
