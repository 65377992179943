/* ---- HISTORY ----- */

.registration__history-container {
  max-height: calc(100vh - 460px);
  overflow-y: auto;
  width: 100%;
}
.registration__history-box {
  margin: 10px 10px;
}
.avatar {
  height: 40px;
  width: 46px;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: var(--nhub-color1);
  color: var(--title-4);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  font-weight: 700;
  font-size: 18px;
}
.avatarSkeleton {background-color: transparent;}
.avatar img {
  height: 30px;
  width: 30px;
  text-align: center;
}

.registration__history-user {
  color: var(--title-1);
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}
.registration__history-subject {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}
.registration__history-description {
  text-align: justify;
  font-size: 12px;
  font-weight: 400;
}
.registration__history-date {
  color: var(--title-1);
  font-size: 12px;
  font-weight: 400;
  text-align: right;
}
