.agent-buttons-container {
  width: 330px;
}
.agent-title h4{
  color: var(--title-1);
  margin-top: 0;
}
.download-info {
  font-size: 12px;
  font-weight: 400;
}
.download-subtitle {
  color: var(--title-2);
  font-weight: 400;
}
.clientSettings-widget {
  margin: 10px 0;
}

#new-connectors-button-container{
  display: flex;
  gap: 10px;
}