.wiki_container {
  background-color: var(--wiki-background-1);
  height: 100%;
  width: 45em;
  position: fixed;
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  border-radius: 5px 5px;
  z-index: 1;
  right: 0;
  top: 71px;
  overflow-y: auto;
  padding-bottom: 70px;
}
.wiki_title {
  position: sticky;
  top: 0;
  background-color: var(--wiki-background-1);
  display: flex;
  align-content: center;
  margin-top: 0;
  padding-bottom: 11px;
  border-bottom: 1px solid var(--wiki-border-1);
}
.wiki_title h4 {
  text-align: center;
  position: relative;
  left: 45%;
  margin: 0;
  padding-top: 10px;
  color: var(--title-1);
  font-size: 14px;
  font-weight: 600;
}
.menu-expireDate {
  margin-left: 25px;
  color: var(--title-3);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

/* Validate Wiki */
.wikiDiff {
  white-space: pre-line;

  width: 100%;
  background: none;
  border: none;
  color: var(--content-text);
  font-size: 12px;
  font-weight: 400;
}
ins {
  text-decoration: none;
  background-color: #d4fcbc;
}

del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .wiki_container {
    width: 100%;
    top: calc((var(--header-height)) + 40px);
  }
}
@media screen and (max-width: 1000px) and (orientation: landscape ){
  .wiki_container {
    width: calc(100% - var(--MainNav-width));
    top: var(--header-height);
  }
}
